import {Component, OnInit} from '@angular/core';
import {ValidateAllFormFields} from '../../../functions/validateAllFormFields';
import {Company} from '../../../models/company';
import {UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ucFirst} from '../../../pipes/uc-first.pipe';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDialogService} from '@covalent/core/dialogs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GlobalService} from '../../../services/global.service';
import {NavigationService} from '../../../services/navigation.service';
import {ErrorDialogComponent} from '../../portal/error-dialog/error-dialog.component';
import moment from 'moment/moment';

@Component({
  selector: 'app-upsert',
  templateUrl: './upsert.component.html',
  styleUrls: ['./upsert.component.scss']
})
export class UpsertComponent extends ValidateAllFormFields implements OnInit {
  __loadingService: TdLoadingService;
  __dialogService: TdDialogService;
  __navigationService: NavigationService;
  __router: Router;
  __snackBar: MatSnackBar;
  __translateService: TranslateService;
  company: Company;
  companyId: string;
  context: string;
  id: string;
  data: any;
  action: string;
  modelName: string;
  loaderName: string;
  currentAction: string;
  _service: any;
  error: any;
  form: UntypedFormGroup;
  formErrors: any = {
    name: '',
  };
  disabledOnEdit = [];
  translations: string[] = [];
  navType = 'page';
  dialogRef: any;
  dialogData: any;

  constructor(_loadingService: TdLoadingService,
              _dialogService: TdDialogService,
              _navigationService: NavigationService,
              _router: Router,
              _snackBar: MatSnackBar,
              service: GlobalService,
              _translateService: TranslateService,
              _route: ActivatedRoute) {
    super();
    this._service = service;
    this.__loadingService = _loadingService;
    this.__router = _router;
    this.__snackBar = _snackBar;
    this.__dialogService = _dialogService;
    this.__translateService = _translateService;
    _translateService.get([
      'delete_x',
      'yes',
      'no',
      'message_delete_x',
      'x_deleted',
      'ok',
      'cancel',
      'close',
      'delete',
      'error_code_tax_id_invalid_title',
      'error_code_tax_id_invalid',
      'added_message',
      'added_title',
      'updated_message',
      'upsert_error_title'
    ]).subscribe((translations: any) => {
      this.translations = translations;
      this.loadErrorTranslations(_translateService);
    });

    this.__navigationService = _navigationService;
    this.context = (_route && _route.routeConfig && _route.routeConfig['context']) || 'driver';
    this.__navigationService.setActionLink(``);
  }

  conditionalFormatting() {
  }

  loadData(): void {
    const self = this;

    this._service.get(this.id, {}).subscribe((data: any) => {
      this.data = data;
      this.disabledOnEdit.forEach((field: string) => {
        self.form.controls[field].disable();
      });
      this.__loadingService.resolve(this.loaderName);
    }, error => {
      console.error(error);
      this.__loadingService.resolve(this.loaderName);
    })
  }

  delete(): void {
    // console.log(`[Company CouponUpsertComponent.delete]: `);
    const self = this;
    const name = self.data.name;
    const message = self.translations['message_delete_x'].formatUnicorn(name);
    const title = self.translations['delete_x'].formatUnicorn(name);

    self.__dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: this.__translateService.instant('cancel'),
      acceptButton: this.__translateService.instant('delete'),
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._service.delete(self.data.id, 'company').subscribe(() => {
          self.__dialogService.openConfirm({
            message: this.__translateService.instant('x_deleted').formatUnicorn(name),
            disableClose: true,
            acceptButton: this.__translateService.instant('ok'),
          }).afterClosed().subscribe(() => {
            this.__router.navigate([`/groups/${self.companyId}/${self.modelName}/`]);
          });
        });
      }
    });
  }

  save(): void {
    if (!this.currentAction) {
      this.currentAction = 'save';
    }
    const self = this;

    const data = this.form.value;
    let saveData = JSON.parse(JSON.stringify(data));

    delete saveData.id;
    delete saveData.modified;
    delete saveData.created;

    if (saveData.phoneNumber && saveData.phoneNumber.e164Number) {
      saveData.phoneNumber = saveData.phoneNumber.e164Number;
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (typeof (data[key]) === 'string') {
          saveData[key] = data[key].trim();
        } else {
          saveData[key] = data[key];
        }
      }
    }

    if (!this.form.valid) {
      return this.validateAllFormFields(this.form);
    }

    /**
     * Call the API
     */
    saveData.companyId = this.companyId;
    saveData = this.saveDataMapping(saveData);

    if (this.action.toLowerCase() === 'add') {
      this.__loadingService.register(this.loaderName);
      this._service.insert(saveData).subscribe(() => {
        setTimeout(function () {
          self.__dialogService.openAlert({
            message: ucFirst(self.__translateService.instant('added_message')),
            disableClose: true,
            title: ucFirst(self.__translateService.instant('added_title')),
            closeButton: self.__translateService.instant('ok'),
          }).afterClosed().subscribe(() => {
            if (self.navType === 'dialog') {
              self.dialogRef.close();
              if (self.dialogData.parent) {
                self.dialogData.parent.loadData();
              }
              self.__loadingService.resolve(self.loaderName);
            } else {
              self.__router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/${self.modelName}`]);
              self.currentAction = null;
            }
          });
        }, 500);
      }, error => {
        self.__dialogService.openAlert({
          message: this.__translateService.instant('error_' + error.error.error.name.toLowerCase()),
          disableClose: true,
          title: this.__translateService.instant('upsert_error_title'),
          closeButton: this.__translateService.instant('ok'),
        }).afterClosed().subscribe(() => {
        });
        self.__loadingService.resolve(this.loaderName);
        self.__loadingService.resolve();
        self.currentAction = null;
      });
    } else {
      this._service.update(this.id, saveData).subscribe((result) => {
        setTimeout(function () {
          self.__loadingService.resolve(self.loaderName);
          self.__loadingService.resolve();
          self.__snackBar.open(ucFirst(self.__translateService.instant('updated_message')), self.__translateService.instant('ok'), {
            duration: 3000,
            panelClass: ['snack-success']
          });
        }, 500);
        self.currentAction = null;
        if (self.navType === 'dialog') {
          self.dialogRef.close();
          if (self.dialogData.parent) {
            self.dialogData.parent.loadData();
          }
        }
        self.saveCompleted(result);
      }, error => {
        if (error.error.code !== 'resource_already_exists') {
          self.currentAction = null;
          self.error = error;
          self.showError(error.error);
        }
        self.__loadingService.resolve(this.loaderName);
        self.__loadingService.resolve();
      });
    }
  }

  ngOnInit(): void {
    if (!this.translations[`page_title`]) {
      this.translations[`page_title`] = `${this.modelName}_page_title`;
    }
    if (this.translations[`page_title`]) {
      this.__navigationService.setBodyTitle(this.translations[`page_title`]);
    }
  }

  saveDataMapping(saveData): any {
    if (saveData.startDate) {
      saveData.startDate = moment(saveData.startDate).format('YYYY-MM-DDTHH:mm:00Z')
    }
    if (saveData.endDate) {
      saveData.endDate = moment(saveData.endDate).format('YYYY-MM-DDTHH:mm:00Z')
    }
    return saveData;
  }

  showError(error): void {
    if (error.error) {
      error = error.error;
    }

    let message = this.translations['default_error_message'];
    let title = this.translations['default_error_title'];

    if (error.name && this.translations[`error_code_${error.code}`]) {
      title = this.translations[`error_code_${error.code}_title`];
      message = this.translations[`error_code_${error.code}`];
    } else if (error.name && this.translations[error.name]) {
      title = this.translations[`${error.name}`];
      message = this.translations[`${error.name}`];
    } else if (error.message) {
      message = error.message;
    }

    this.__dialogService.open(ErrorDialogComponent,
      {
        maxHeight: '80%',
        width: '380px',
        data: {
          message: message,
          title: title,
          closeButton: this.translations[`close`]
        },
      });
  }

  saveCompleted(result: any) {

  }
}
